<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">答题红包活动列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">红包领取详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="领取日期" class="searchboxItem ci-full">
              <span class="itemLabel">领取日期:</span>
              <el-date-picker
                v-model="activityDate"
                type="datetimerange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>
            <div title="奖项" class="searchboxItem ci-full">
              <span class="itemLabel">奖项:</span>
              <el-select v-model="prizeId" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in prizeList"
                  :key="item.prizeId"
                  :label="item.prizeName"
                  :value="item.prizeId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="getExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="numJson">
          <span>共分配奖池：{{ numJson.totalAmount || 0 }}元</span>
          <span>领取金额：{{ numJson.drawBonus || 0 }}元</span>
          <span>抽奖人次：{{ numJson.userNum || 0 }}人</span>
          <span>中奖人次：{{ numJson.drawUserNum || 0 }}人</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="微信名称"
                align="center"
                show-overflow-tooltip
                prop="name"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
              />
              
              <el-table-column
                label="奖项"
                align="center"
                show-overflow-tooltip
                prop="prizeName"
              />
              <el-table-column
                label="中奖金额"
                align="center"
                show-overflow-tooltip
                prop="bonus"
              />
              <el-table-column
                label="备注"
                align="center"
                show-overflow-tooltip
                prop="remarks"
              />
              <el-table-column
                label="中奖日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "activeConfigurationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activityDate: "", //领取日期
      prizeId: "", //奖项Id
      prizeList: [], // 奖项数据
      activityId: "",
      numJson: {},
    };
  },
  watch: {},
  created() {
    this.activityId = this.$route.query.activityId;
    this.getPrizeTree();
    this.getDrawCount();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {},
  methods: {
    //获取行政区划
    getPrizeTree() {
      this.$post("/biz/activity/getPrize", { activityId: this.activityId })
        .then((res) => {
          this.prizeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    //统计
    getDrawCount() {
      const params = {
        activityId: this.$route.query.activityId,
      };
      if (this.prizeId) {
        params.prizeId = this.prizeId;
      }
      if (this.activityDate) {
        params.startTime = this.activityDate[0];
        params.endTime = this.activityDate[1];
      }
      this.$post("/biz/activity/getDrawCount", params)
        .then((res) => {
          this.numJson = res.data;
          console.log(res.data);
        })
        .catch(() => {
          return;
        });
    },
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        activityId: this.$route.query.activityId,
      };
      if (this.prizeId) {
        params.prizeId = this.prizeId;
      }
      if (this.activityDate) {
        params.startTime = this.activityDate[0];
        params.endTime = this.activityDate[1];
      }
      this.doFetch({
        url: "/biz/activity/getDrawList",
        params,
        pageNum,
      });
      this.getDrawCount();
    },
    //导出
    getExport() {
      const params = {
        activityId: this.$route.query.activityId,
      };
      if (this.prizeId) {
        params.prizeId = this.prizeId;
      }
      if (this.activityDate) {
        params.startTime = this.activityDate[0];
        params.endTime = this.activityDate[1];
      }
      this.$post("/biz/activity/drawList/export", params)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.75) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
};
</script>
<style lang="less" scope>
.numJson {
  span {
    padding: 10px;
  }
}
</style>